import React, { lazy } from 'react';
import 'antd/dist/antd.min.css';
import styles from './App.module.css';
import { Layout } from 'antd';

const Head = lazy(() => import('./layout/head'));
const Foot = lazy(() => import('./layout/footer'));

const { Header, Footer, Content } = Layout;

console.info(styles)

function App() {
    return (
        <Layout>
            <Header className={styles.header}>
                <Head />
            </Header>
            <Content className={styles.main}>
                <p>感谢您的点击，这是demo中的demo，后面会以很快的速度构建起来主要模块</p>
                <p>网站的目的后面有大概一个，就是分享一下一些数据，后面见~</p>
                <a href='/b.html'>禁止点击</a>
            </Content>
            <Footer className={styles.footer}>
                <Foot />
            </Footer>
        </Layout>
    );
}

export default App;
